import React from 'react'
import { graphql } from 'gatsby'
import Layout from "../components/layout"

const Resume = ({ data }) => (
  <Layout>
    <div className="content" style={{ height: "80vh" }}>
      <header className="intro_text text-dark" style={{ maxWidth: "640px", paddingTop: "3rem" }}>
        <div className="intro_container">
          <span className="text-small text-medium uppercase text-primary">
            {data.datoCmsTopSectionIntro.headline}
          </span>
          <h3 className="uppercase text-bold text-sans-serif text-display-xl">
            {data.datoCmsTopSectionIntro.line1}
            <span className="text-grey-two text-regular">{data.datoCmsTopSectionIntro.inline}</span>
            <br />
            {data.datoCmsTopSectionIntro.line2}
          </h3>
          <div className="text-display-m hero_content_spacing"
            dangerouslySetInnerHTML={{
              __html:
                data.datoCmsTopSectionIntro.snippetNode.childMarkdownRemark.html
            }}
          />
        </div>
      </header>

    </div>
  </Layout>
)

export default Resume


export const cv = graphql`
  query Cv {
      datoCmsTopSectionIntro(sectionName: {eq: "cv"}) {
        headline
        line1
        inline
        line2
        snippetNode {
          childMarkdownRemark {
            html
          }
        }
      }
  }
`;
